import React from 'react';
import Card from '../card/card';

import * as styles from './cardGrid.module.css'

const CardGrid = ({ data }) => {
    return (
        <div className={styles.grid}>
            {data && data.map((card, cardIndex) => (
                <Card key={cardIndex} type={'simple'} data={card} />
            ))}
        </div>
    )
}

export default CardGrid