import React from 'react'
import { graphql } from 'gatsby'
import GraphQLErrorList from '../../components/graphql-error-list'
import SEO from '../../components/seo'
import Layout from '../../containers/layout'
import Container from '../../components/container'
import CardGrid from '../../components/hub/cardGrid/cardGrid'
import SubscribeBar from '../../components/subscribeBar/subscribeBar'

import * as styles from '../../components/globals-v2.module.css'

export const query = graphql`
  query AudioHubQuery {
    page: sanityPage(title: { eq: "Content Hub" }) {
        title
        metaTitle
        metaDescription
        metaKeywords
        metaImage {
          asset {
            _id
            url
          }
        }
    }

    audio: allSanityAudio(sort: {order: DESC, fields: publishedAt}) {
        nodes {
            _type
            tags {
              title
              slug {
                current
              }
            }
            title
            slug {
              current
            }
            appleUrl
            spotifyUrl
            youTubeUrl
        }
    }
  }
`

const AudioHub = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const page = data.page;
  const audioPosts = data.audio.nodes;

  if (!page) {
    throw new Error(
      'Missing "Audio Hub" page data. Open the studio at http://localhost:3333 and add "Audio Hub" page data and restart the development server.'
    )
  }

  return (
    <Layout hideContactBar={true} hideAnnouncements={true} logoOverrideUrl="/content-hub/" darkMode={true} version="2">
        <SEO title={page.metaTitle || page.title} description={page.metaDescription} keywords={page.metaKeywords.split(',')} imageFB={page.metaImage} />

        <div className={styles.backgroundControl}>
            <Container size="large" class={styles.sectionPad}>
                <section className={`audioPosts`}>
                    <h6>Audio</h6>
                    <CardGrid data={audioPosts} />
                </section>
            </Container>
        </div>

        <div className={styles.backgroundControlAlt}>
            <Container size="large">
                <SubscribeBar />
            </Container>
        </div>

    </Layout>
  )
}

export default AudioHub
