import React, { useState } from 'react'
import queryString from 'query-string'
import jsonp from 'jsonp'
import Button from '../button/button'
import Container from '../container'

import * as styles from './subscribeBar.module.css'

const SubscribeBar = () => {
  const [email, setEmail] = useState('')
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(null)
  const action = 'https://redpoint.us9.list-manage.com/subscribe/post-json?u=303bf0d309203a3ab69ac6668&id=6aca60fec3&f_id=00a704e1f0'

  const submit = (e) => {
    e.preventDefault()

    const data = {
      EMAIL: email,
      b_303bf0d309203a3ab69ac6668_6aca60fec3: ''
    }
    jsonp(`${action}&${queryString.stringify(data)}`, { param: 'c' }, (err, data) => {
      if (data.result === 'success') {
        setSuccess(data.msg)
      } else if (data.result === 'error') {
        setError(data.msg)
      }
    })
  }

  return (
    <div className={styles.bar}>
      <Container>
        {!success && (
          <>
            <h4>Be a founder who's in the know.</h4>
            <form action={action} method='GET' id='mc-embedded-subscribe-form' name='mc-embedded-subscribe-form' className='validate' noValidate onSubmit={(e) => submit(e)}>
              <input type='email' name='EMAIL' className='required email' id='mce-EMAIL' required placeholder='Your email' defaultValue='' onChange={(e) => setEmail(e?.target.value)} />
              <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden='true'>
                <input type='text' name='b_303bf0d309203a3ab69ac6668_6aca60fec3' tabIndex='-1' value='' />
              </div>
              <Button element='buttonSubmit'>Subscribe</Button>
              {error && (
                <div className={styles.error}>
                  {error}
                </div>
              )}
            </form>
          </>
        )}

        {success && (
          <h4 style={{ textAlign: 'center' }}>{success}</h4>
        )}

        {/*
                <script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script>
                <script type='text/javascript'>(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';fnames[5]='BIRTHDAY';ftypes[5]='birthday';}(jQuery));var $mcj = jQuery.noConflict(true);</script>
                */}

      </Container>
    </div>
  )
}

export default SubscribeBar
